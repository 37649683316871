/* 
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.state = { errorInfo: false }
  }


  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    this.setState({ errorInfo: error.toString() + errorInfo.componentStack });
  }

  render() {
    if (this.state.hasError) {

      return (
        <div className="flex flex-col justify-center items-center h-[100vh] w-full bg-white p-5">
          <img className="max-w-[200px]" src="/img/others/img-2.png" />
          <div className="mt-6 text-center">
            <h3 className="mb-2">Ups! Algo salio mal.</h3>
            <p>Por favor copia esta pantalla y enviarla al equipo de soporte de StoreHub. Gracias!</p>
            <button className="button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 m-4">
              <a href="/home">Volver a Inicio</a>
            </button>
            <p className='text-red-500	 p-4'>{this.state.errorInfo}</p>
          </div>
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary; */

// ErrorBoundary.js
import React, { Component } from 'react';

// constants.js
 const ERROR_MESSAGES = {
  DEFAULT: 'Ups! Algo salió mal.',
  SUPPORT: 'Por favor copia esta pantalla y enviarla al equipo de soporte de StoreHub. Gracias!',
  API_ERROR: 'Error al conectar con el servidor',
};

 const ENDPOINTS = {
  LOG_ERROR: 'https://automation.cubiq.digital/webhook/error-storehub',
  // Agrega más endpoints según necesites
};


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      errorInfo: '',
      isLoading: false
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ 
      hasError: true,
      errorInfo: error.toString() + errorInfo.componentStack 
    });
    
    this.logErrorToServer(error, errorInfo);
  }

  getDeviceType = () => {
    const ua = navigator.userAgent;
    
    // Detectar si es móvil y obtener información específica
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(ua)) {
      // Es un dispositivo móvil, determinar el modelo
      let deviceModel = 'Dispositivo móvil';
      
      // Detectar iPhone y su versión
      const iPhoneMatch = ua.match(/iPhone\s+OS\s+(\d+)/i);
      if (iPhoneMatch) {
        deviceModel = `iPhone iOS ${iPhoneMatch[1]}`;
      }
      
      // Detectar Android y su versión
      const androidMatch = ua.match(/Android\s+(\d+(\.\d+)?)/i);
      if (androidMatch) {
        deviceModel = `Android ${androidMatch[1]}`;
        
        // Intentar obtener la marca/modelo
        const modelMatch = ua.match(/;\s*([^;]+(?:(?!Build)\S)*)/i);
        if (modelMatch) {
          deviceModel += ` - ${modelMatch[1].trim()}`;
        }
      }
      
      return {
        type: 'mobile',
        model: deviceModel
      };
    }
    
    // Detectar si es tablet
    if (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(ua)) {
      return {
        type: 'tablet',
        model: 'Tablet'
      };
    }
    
    // Detectar si es notebook o PC
    const isNotebook = /Laptop|Macbook|notebook/i.test(ua) || 
                      (window.screen.width < 1600 && navigator.maxTouchPoints > 0);
    
    return {
      type: isNotebook ? 'notebook' : 'desktop',
      model: `${navigator.platform} - ${navigator.vendor}`
    };
  };

  getBrowserInfo = () => {
    const ua = navigator.userAgent;
    let browserName = "";
    let browserVersion = "";

    // Detectar Chrome
    if (ua.includes("Chrome/")) {
      browserName = "Chrome";
      browserVersion = ua.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)/)?.[1] || "";
    }
    // Detectar Firefox
    else if (ua.includes("Firefox/")) {
      browserName = "Firefox";
      browserVersion = ua.match(/Firefox\/(\d+\.\d+)/)?.[1] || "";
    }
    // Detectar Safari (no Chrome)
    else if (ua.includes("Safari/") && !ua.includes("Chrome/")) {
      browserName = "Safari";
      browserVersion = ua.match(/Version\/(\d+\.\d+)/)?.[1] || "";
    }
    // Detectar Edge
    else if (ua.includes("Edg/")) {
      browserName = "Edge";
      browserVersion = ua.match(/Edg\/(\d+\.\d+\.\d+\.\d+)/)?.[1] || "";
    }

    return `${browserName} ${browserVersion}`;
  };

  getDeviceInfo = () => {
    const deviceTypeInfo = this.getDeviceType();
    return {
      browser: this.getBrowserInfo(),
      deviceType: deviceTypeInfo.type,
      deviceModel: deviceTypeInfo.model,
      language: navigator.language,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      windowSize: `${window.innerWidth}x${window.innerHeight}`,
      connection: navigator.connection ? {
        effectiveType: navigator.connection.effectiveType,
        downlink: navigator.connection.downlink,
        rtt: navigator.connection.rtt
      } : null
    };
  };

  getUserInfo = () => {
    try {
      const adminData = localStorage.getItem('admin');
      if (adminData) {
        const parsedData = JSON.parse(adminData);
        const authData = JSON.parse(parsedData.auth);
        
        return {
          email: authData.user.email || 'No email found',
          userName: authData.user.userName,
          userLastname: authData.user.userLastname,
          role: authData.user.authority.join(', '),
          timezone: authData.user.timezone.code
        };
      }
      return { 
        email: 'No user data found',
        userName: 'Unknown',
        userLastname: 'Unknown',
        role: 'Unknown',
        timezone: 'Unknown'
      };
    } catch (e) {
      console.error('Error parsing admin data from localStorage:', e);
      return { 
        email: 'Error getting user data',
        userName: 'Error',
        userLastname: 'Error',
        role: 'Error',
        timezone: 'Error'
      };
    }
  };

  logErrorToServer = async (error, errorInfo) => {
    this.setState({ isLoading: true });
    try {
      const deviceInfo = this.getDeviceInfo();
      const userInfo = this.getUserInfo();
      
      const response = await fetch(ENDPOINTS.LOG_ERROR, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          error: error.toString(),
          errorInfo: errorInfo.componentStack.slice(0,200),
          url: window.location.href,
          timestamp: new Date().toISOString(),
          deviceInfo,
          userInfo
        })
      });

      if (!response.ok) {
        console.error('Error al enviar el log:', response.statusText);
      }
    } catch (fetchError) {
      console.error('Error en la petición:', fetchError);
      this.setState({ 
        errorInfo: this.state.errorInfo + '\n' + ERROR_MESSAGES.API_ERROR 
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleRetry = () => {
    this.setState({ hasError: false, errorInfo: '' });
    window.location.reload();
  }

  render() {
    const { hasError, errorInfo, isLoading } = this.state;

    if (hasError) {
      return (
        <div className="flex flex-col justify-center items-center h-screen w-full bg-white p-5">
          <img 
            className="max-w-[200px]" 
            src="/img/others/img-2.png" 
            alt="Error illustration"
          />
          <div className="mt-6 text-center">
            <h3 className="mb-2">{ERROR_MESSAGES.DEFAULT}</h3>
            <p>{ERROR_MESSAGES.SUPPORT}</p>
            <div className="flex justify-center gap-4 mt-4">
              <button 
                className="bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white rounded px-8 py-2"
                onClick={this.handleRetry}
                disabled={isLoading}
              >
                {isLoading ? 'Cargando...' : 'Reintentar'}
              </button>
              <a 
                href="/home"
                className="bg-gray-200 hover:bg-gray-300 active:bg-gray-400 text-gray-700 rounded px-8 py-2"
              >
                Volver a Inicio
              </a>
            </div>
            {errorInfo && (
              <p className="text-red-500 p-4 mt-4 text-sm break-words">
                {errorInfo}
              </p>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;