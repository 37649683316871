import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import ticketsGraphqlData from './graphql/ticket'
import ticketList from '../views/tickets/store/index'
import countriesTimezonesLanguages from "./graphql/countries_timezones_language"
import camerasStoreData from '../views/cameras/store/index'
import knowledgeBaseHelpCenter from "../views/helpCenter/store/index"
import dataProfile from 'views/account/store/index'
import dataCalibration from "views/metricsCalibration/store/index"
import dataHome from "views/home/store/index"


const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        ticketsGraphqlData,
        countriesTimezonesLanguages,
        ticketList,
        camerasStoreData,
        knowledgeBaseHelpCenter,
        dataProfile, 
        dataCalibration, 
        dataHome, 
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
