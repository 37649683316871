import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { incidentData } from 'mock/data/incidentData';
import { apiGetChannels } from 'services/ChannelsService';
import { apiGetStoreList } from 'services/StoreService';
import {  apiGetTicketList } from 'services/TicketsService'


export const getTicketsDashboard = createAsyncThunk('ticketsList/getTicketsDashboard', async (data) => {
    //El {data} trae la query
    const response = await apiGetTicketList(data)
    return response.data
})
export const getStorestList = createAsyncThunk('storesList/getList', async (data) => {
    const response = await apiGetStoreList(data)
    return response.data
})
export const getPersonalChannel = createAsyncThunk('channelsList/getPersonalChannelsList', async (data) => {
    const response = await apiGetChannels(data)
    return response.data
})

export const getDataStatistics= createAsyncThunk('channelsList/getDataStatistics', async (data) => {
    const response = await apiGetTicketList(data)
    return response
})



export const initialTableData = {
/*     total: 0,
    pageIndex: 1,
    pageSize: 30,
    pageFrom: 0,
    pageTo: 1, */
    queryTickets: '/tickets',
    queryStores : "/stores", 
    queryChannels : "/channels?filter[personal_channel]=true",
    queryStatistics:""
   /*  sort: {
        order: '',
        key: '',
    } */
}


const dataSlice = createSlice({
    name: 'dashboardHome/data',
    initialState: {
        tableData: initialTableData,
        ticketList: [],
        storesList:[], 
        personalChannelsList:[],
        dataStatistics: [],  
        loadingTickets: false,
        loadingStores:false, 
        loadingPersonalChannels: false, 
        loadingSelectedTicket: false,
        loadingDataStatistics: true
    },
    reducers: {
   
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setLoadingSate: (state, action) => {
            state.loading = action.payload
        },
   
    },
    extraReducers: {
        [getTicketsDashboard.fulfilled]: (state, action) => {
            state.ticketList = action.payload.data
            state. loadingTickets = false
        },
    
        [getTicketsDashboard.pending]: (state) => {
            state. loadingTickets = true
        },
        [getStorestList.fulfilled]: (state, action) => {
            state.storesList = action.payload.data
            state.loadingStores = false
        },
    
        [getStorestList.pending]: (state) => {
            state.loadingStores = true
        },
        [getPersonalChannel.fulfilled]: (state, action) => {
            state.personalChannelsList = action.payload.data
            state.loadingPersonalChannels = false
        },
    
        [getPersonalChannel.pending]: (state) => {
            state.loadingPersonalChannels = true
        },
        [getDataStatistics.fulfilled]: (state, action) => {
            state.dataStatistics = action.payload.data
            state.loadingDataStatistics = false
        },
    
        [getDataStatistics.pending]: (state) => {
            state.loadingDataStatistics = true
        },
    }
})

export const { setTableData,  setLoadingSate,} = dataSlice.actions

export default dataSlice.reducer

