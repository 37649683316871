import ApiService from "./ApiService";

export async function apiGetChannels (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}

export async function apiPostChannels (data) {
    return ApiService.fetchData({
        url: `/channels`,
        method: 'post',
        data
    })
}

export async function apiPutChannels (id, data) {
    return ApiService.fetchData({
        url: `/channels/${id}`,
        method: 'put',
        data
    })
}

export async function apiDeleteChannels (id) {
    return ApiService.fetchData({
        url: `/channels/${id}`,
        method: 'delete',
    })
}
